/*@media (min-width: 768px) {
  .example {
    margin-left: 0;
    margin-right: 0;
    background-color: #fff;
    border-width: 1px;
    border-color: #ddd;
    border-radius: 4px 4px 0 0;
    box-shadow: none;
  }
}
.example {
  position: relative;
  padding: 45px 15px 15px;
  margin: 0 -15px 15px;
  background-color: #fafafa;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, .05);
  border-color: #e5e5e5 #eee #eee;
  border-style: solid;
  border-width: 1px 0;
}*/

.example {
  position: relative;
  padding: 45px 15px 15px;
  background-color: #fff;
  border-color: #ddd;
  border-style: solid;
  border-width: 1px;
}

.example:after {
  content:"Example";
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 12px;
  font-weight: 700;
  color: #bbb;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.form-block .block-ui-message-container {
  top: 30%;
}

.example-container {
  min-height: 300px;
  overflow: hidden;
}

/*.example-container.block-ui-active {*/
  /*background-color: yellow;*/
/*}*/

/*.example-container.block-ui-visible {*/
  /*background-color: green;*/
/*}*/

/*.login-form-block .block-ui-overlay.block-ui-visible {
  background-color: yellow;
}*/

